import Device from "@/app/modules/_global/classes/Device";

// https://developer.apple.com/design/human-interface-guidelines/ios/visual-design/adaptivity-and-layout/

const SIZE = {
  _375_667: { width: 375, height: 667 }, // Includes: iPhone SE (2nd, 3rd gen)
  _375_812: { width: 375, height: 812 }, // Includes: X, Xs, 11 Pro
  _414_896: { width: 414, height: 896 }, // Includes: Xr, Xs Max, 11, 11 Pro Max
  _390_844: { width: 390, height: 844 }, // Includes: 12, 12 Pro, 13, 13 Pro, 14
  _430_932: { width: 430, height: 932 }, // Includes: 13 Pro Max, 14 Pro Max, 15 Pro Max
  _393_852: { width: 393, height: 852 }, // Includes: 14 Pro, 15, 15 Pro
  _428_926: { width: 428, height: 926 }, // Includes: 14 Plus
  _435_950: { width: 435, height: 938 }, // Includes: 16, 16 Pro
  _440_950: { width: 440, height: 950 } // Includes: 16 Pro Max
};

const CONTENT_OFFSETS = {
  80: [SIZE._375_667, SIZE._375_812, SIZE._390_844, SIZE._393_852, SIZE._428_926, SIZE._435_950],
  84: [SIZE._414_896, SIZE._430_932, SIZE._440_950]
};

const SIDEBAR_HEIGHTS = {
  58: [SIZE._375_667, SIZE._375_812, SIZE._390_844, SIZE._393_852, SIZE._428_926, SIZE._435_950],
  54: [SIZE._414_896, SIZE._430_932, SIZE._440_950]
};

export default class Screen {
  private _header: HTMLCollectionOf<HTMLElement>;
  private _footer: HTMLCollectionOf<HTMLElement>;

  private _headerOffsetHeight: number;
  private _footerOffsetHeight: number;

  private _screenSize: object;

  constructor() {
    this._header = document.getElementsByTagName("header");
    this._footer = document.getElementsByTagName("footer");

    this._headerOffsetHeight = this._header[0].offsetHeight;
    this._footerOffsetHeight = this._footer[0].offsetHeight;

    this._screenSize = new Device().getDeviceScreenSize;
  }

  getBoundedContentHeight(): number {
    const offset = this._getOffsetForScreenSize(CONTENT_OFFSETS, 48); // Default is 48 for Vuetify padding
    return this._headerOffsetHeight + this._footerOffsetHeight + offset;
  }

  getBoundedSidebarHeight(): number {
    return this._getOffsetForScreenSize(SIDEBAR_HEIGHTS, 24); // Default sidebar padding
  }

  private _getOffsetForScreenSize(map: Record<number, object[]>, defaultOffset: number): number {
    for (const [offset, devices] of Object.entries(map)) {
      if (devices.some(device => this._screenSizeIs(device, this._screenSize))) {
        return Number(offset);
      }
    }

    return defaultOffset;
  }

  private _screenSizeIs(constantDevice: object, currentDevice: object): boolean {
    return JSON.stringify(constantDevice) === JSON.stringify(currentDevice);
  }
}
